import React from "react";

// import Layout from "../components/layout";
// import SEO from "../components/seo";
// import Section from "../components/section";

// import Filters from "../components/search-results/filters";
// import ResultsList from "../components/search-results/results-list";
// import Pagination from "../components/search-results/pagination";

import { graphql } from "gatsby";
// import ResultsMap from "../components/search-results/results-map";
import SearchContainer from "../components/search-results/search-container";

const SearchResults = ({
  data: { gaiaPage },
  location,
  pageContext: { pageInfo, baseURL },
}) => {
  const title = gaiaPage.parent_pages
    .slice(1)
    .map(({ name }) => name)
    .join(" • ");

  return (
    <SearchContainer
      listId={gaiaPage.id}
      baseURL={baseURL}
      location={location}
      title={title}
      initialPageInfo={pageInfo}
      initialProperties={gaiaPage.property_pages}
      initialFilters={gaiaPage.list_filters}
      initialPagination={page => {
        if (page === 1) {
          return baseURL;
        } else {
          return `${baseURL}/${page}`;
        }
      }}
    />
  );
};
export default SearchResults;

export const query = graphql`
  query SearchQuery($id: String, $skip: Int!, $limit: Int!) {
    gaiaPage(id: { eq: $id }) {
      id
      name
      parent_pages {
        name
      }
      list_filters {
        diamond_deal
        has_discounts
        property_type
        region
        size
      }
      property_pages(skip: $skip, limit: $limit) {
        id
        name
        url
        diamond_deal
        has_discounts
        first_picture {
          url
          placeholder
        }
        property {
          id
          starting_price
          marketing_name
          property_types
          gps_coordinates
          occupancy
          indoorfeatures {
            area_sqm
            extra_beds
            bedrooms
            bathrooms
          }
          pool {
            length
            width
          }
        }
        # fields {
        #   first_picture {
        #     childImageSharp {
        #       fluid(maxWidth: 580, maxHeight: 387) {
        #         ...GatsbyImageSharpFluid
        #       }
        #     }
        #   }
        # }
      }
    }
  }
`;
